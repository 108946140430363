.row {
    display: flex;
    flex-direction: row;
}

.col {
    display: flex;
    flex-direction: column;
}

.wrap {
    flex-wrap: wrap;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: end;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.margin {
    margin: auto;
}

.max-width {
    width: 100%;
}

.gap-xsm {
    gap: .25rem;
}

.gap-sm {
    gap: .5rem
}

.gap {
    gap: 1rem;
}

.gap-lg {
    gap: 2rem;
}

.pad-sm {
    padding: .5rem;
}

.pad {
    padding: 1rem;
}

.pad-lg {
    padding: 2rem;
}

.radius-sm {
    border-radius: .25rem;
}

.radius {
    border-radius: .5rem;
}

.rounded {
    border-radius: 100%;
}

.interactable:hover {
    cursor: pointer;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.disabled {
    display: none !important;
}

.blur {
    filter: blur(5px);
}

.text-end {
    text-align: end;
}

.text-center {
    text-align: center;
}