.navbar {
    z-index: 4;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    font-family: 'Montserrat';
    color: var(--text-400);
    background-color: var(--base-400);
    border-bottom: 1px solid var(--base-500);
    width: 100%;
    height: 4rem;
    transform: translate(0, -5rem);
    transition: color .5s, transform .5s;
}

.navbar--panel {
    transform: translate(0, 0) !important;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    right: 0;
    border-bottom: none;
    color: var(--text-400) !important;
    background-color: var(--base-400) !important;
}

.navbar--top {
    color: var(--base-500);
    background-color: transparent;
    border: none;
}

.navbar--show {
    transform: translate(0, 0);
}

.navbar__item {
    font-size: large;
    padding: .5rem 1rem;
    border-radius: 1.5rem;
    transition: transform .25s ease, color .5s;
}

.navbar__item:hover {
    background-color: #ffffff2d;
    transform: translate(0, -3px);
}

.navbar--top .navbar__item:hover {
    color: var(--base-300);
}

.navbar__brand {
    max-height: 3rem;
    margin-right: 1rem;
    position: fixed;
    left: 1rem;
    top: .5rem;
}

.navbar__burger {
    margin-left: auto;
    color: inherit;
    top: 1rem;
    right: 1rem;
}
