.console {
    z-index: 4;
    word-wrap: break-word;
    resize: both;
    position: fixed;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 35rem;
    height: 20rem;
    right: calc(2rem + 3em);
    bottom: 1rem;
    color: var(--base-500);
    background-color: var(--base-800);
    outline: 1px solid var(--base-800);
    transition: opacity .5s;
}

.console--maximized {
    top: 5rem !important;
    left: 1rem !important;
    height: calc(100vh - 6rem) !important;
    width: calc(100vw - 4rem - 3em) !important;
}

.console--minimized {
    pointer-events: none;
    opacity: 0;
}

.console--dragging:hover {
    transition: none;
    cursor: grab;
}
  
.titlebar {
    display: flex;
    align-items: center;
    overflow: hidden;
    color: var(--base-800);
    width: 100%;
    min-height: 2rem;
    background-color: var(--base-400);
}
  
.titlebar__button {
    padding: .5rem 1rem;
    z-index: 2;
    transition: background-color .25s, color .25s;
}

.titlebar__button:hover {
    background-color: var(--base-500);
}

.titlebar__button--close:hover {
    color: var(--base-300);
    background-color: #e81123;
}

.console__button {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 4;
    color: var(--base-800);
    margin-bottom: auto;
    transition: color .5s;
}

.console__button--top {
    color: var(--base-300);
}
  
.console a:hover {
    cursor: pointer;
    background-color: var(--base-400);
    color: var(--base-800);
}

.console__content {
    white-space: pre;
    overflow-y: scroll;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-family: 'consola';
    font-weight: 300;
}

.console__content p {
    word-wrap: break-word;
}

.console__input {
    background-color: transparent;
    font-family: 'consola';
    font-weight: 300;
    font-size: medium;
    color: var(--base-400);
    outline: none;
    border: none;
    caret-color: transparent;
    width: 1rem;
    padding: 0;
    outline: 1px solid var(--base-300);
}

.console__text {
    color: var(--base-400);
    font-weight: 500;
}

@media only screen and (max-device-width: 900px) {

    .console {
        display: none;
    }

    .console__button {
        display: none;
    }

}