.about {
    position: relative;
    scroll-snap-align: center;
    display: flex;
    max-width: 60rem;
    margin: auto;
    gap: 5rem;
    min-height: 100vh;
    padding: 5rem 1rem;
}

.about__header-container {
    display: flex;
    align-items: flex-end;
    width: 100%;
    min-height: 5rem;
}

.about__header-text {
    font-family: 'consola';
    font-size: 3.5rem;
}

.about__profile-container {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
}

.about__portrait {
    align-self: flex-start;
    max-height: 15rem;
    border-radius: 100%;
    margin: 0 auto;
}

.about__quote {
    font-size: 1.2rem;
    font-family: 'Comfortaa';
}

.techstack {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(8, 1.5rem);
    grid-auto-flow: column;
    gap: .5rem;
    margin-top: 1rem;
}

.tech {
    display: flex;
    gap: .5rem;
    max-height: 1.5rem;
}

@media only screen and (max-device-width: 900px) {

    .about {
        flex-direction: column-reverse;
    }

    .about__header-text {
        font-size: 2rem;
    }

    .about__profile-container {
        margin-top: 0;
    }

    .about__header-container {
        min-height: auto;
    }

}