.projects {
    scroll-snap-align: center;
    position: relative;
    min-height: 100vh;
    background-color: var(--base-400);
    border-top: 1px solid var(--base-500);
}

.projects-container {
    position: relative;
    gap: 2rem 1rem;
    padding: 5rem 1rem;
    display: grid;
    max-width: calc(350px * 3 + 4rem);
    width: min-content;
    grid-template-columns: repeat(3, 350px);
    margin: auto;
    transition: padding 1s, translate .5s ease;
}

.projects-container::-webkit-scrollbar {
    display: none;
}

.project__items {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
}

.project__items--tech {
    margin-top: auto;
    padding-top: 2rem;
}

@media only screen and (max-device-width: 1100px) {

    .projects-container {
        grid-template-columns: 350px 350px;
    }

}

@media only screen and (max-device-width: 750px) {

    .projects-container {
        grid-template-columns: 1fr;
        width: max-content;
    }

}

@media only screen and (max-device-width: 400px) {

    .projects-container {
        grid-template-columns: 1fr;
        width: 100%;
    }
    
}
