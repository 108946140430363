.home {
    position: relative;
    z-index: 1;
    scroll-snap-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* background-image: url('../images/home.png'); */
    background-color: #090814;
    background-position: center;
    background-size: cover;
}

.home__content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;
}

.home__content b {
    font-weight: bold;
    font-family: "consola";
    color: var(--accent-300);
}

.home__content h1 {
    color: var(--base-300);
    font-size: 5rem;
}

.home__content h3 {
    color: var(--base-500);
    font-family: 'Comfortaa';
    font-size: 2rem;
    text-align: center;
}

.home__content button {
    margin-top: 1rem;
    font-size: 1.25rem;
    font-family: "consola";
    color: var(--base-300);
    border: 1px solid var(--base-500);
    border-radius: 2rem;
    padding: 1rem 2rem;
    width: max-content;
    transition: background-color .5s, color .5s, box-shadow .5s, transform .5s;
}

.home__content button:hover {
    color: var(--base-300);
    background-color: #ffffff2d;
    transform: translate(0, -10px);
}

.home__hero-container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.home__hero-text {
    background-color: var(--accent-500);
    color: var(--base-400);
    padding: 1rem;
}

@media only screen and (max-device-width: 1000px) {

    .home__content {
        justify-content: center;
        align-items: center;
    }

    .home__hero-container {
        flex-direction: column;
    }
}
