
.footer {
    height: 10rem;
    scroll-snap-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: .8rem;
    gap: 2rem;
    background-color: var(--base-400);
    border: 1px solid var(--base-500);
}

.footer__socials {
    display: none;
}
