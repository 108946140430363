.contact {
    overflow: hidden;
    position: relative;
    display: flex;
    padding: 5rem 2rem;
    margin: auto;
    min-height: calc(100vh - 10rem);
}

.contact__form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
}

.contact h2 {
    font-size: 3rem;
}

.contact__row {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.contact__input {
    padding: 1rem;
    font-size: 1rem;
    color: var(--text-300);
    background-color: var(--base-400);
    width: 100%;    
    border: 1px solid var(--shadow-500);
    border-radius: .25rem;
}

.contact__input:focus {
    outline: none;
    border: 1px solid var(--accent-300);
    color: var(--accent-500);
}

.contact__message {
    font-family: 'Inter', -apple-system, system-ui, sans-serif;
}

.contact__button {
    border: none;
    border-radius: .25rem;
    color: white;
    background-color: var(--accent-500);
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Inter', -apple-system, system-ui, sans-serif;
    padding: 1rem;
    width: 100%;
    margin: 0 auto;
    transition: all .25s ease-in-out;
}

.contact__input-container {
    display: flex;
    gap: 1rem;
}

@media only screen and (max-device-width: 900px)  {

    .contact {
        flex-direction: column-reverse;
    }

    .contact__row {
        flex-direction: column;
    }
}