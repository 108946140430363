.modal {
    z-index: 4;
    pointer-events: none;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    opacity: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding: 2rem;
    background-color: rgba(17, 16, 19, 0.678);
    transition: opacity .5s;
}

.modal--expanded {
    pointer-events: all;
    opacity: 1;
}

.modal__button-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.modal__button {
    height: 100%;
    color: var(--base-500);
    transition: color .5s;
}

.modal__button--close {
    position: absolute;
    top: 0;   
    height: auto;
}

.modal button:hover {
    color: var(--base-300);
}

.modal__content {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
}

.modal__banner {
    height: 55vh;
    object-fit: cover;
}

.modal__panel {
    position: relative;
    overflow-y: scroll;
    background-color: var(--base-400);
    box-shadow: 0 1rem 2rem var(--shadow-800);
    height: 100%;
    /* max-width: calc(350px * 3 + 2rem);
    max-height: calc(350px * 2 + 3rem); */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    aspect-ratio: 16 / 9;
}

.screenshot {
    max-width: 100%;
}

.modal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.modal__content-row {
    display: flex;
    gap: 2rem;
}

.modal__tag {
    padding: .5rem;
    background-color: var(--accent-500);
    border-radius: .25rem;
    color: var(--base-400);
}

.modal__art-container {
    max-width: calc(100vw - 2rem);
}

.modal__art {
    max-height: calc(100vh - 4rem);
    height: 100%;
    width: 100%;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.modal__loading {
    color: var(--base-500);
    max-height: 2.5rem;
    animation: loading 1s infinite;
}

@media only screen and (max-device-width: 500px) {

    .modal {
        padding: 1rem .25rem;
        gap: 0;
    }

}

@media only screen and (max-device-width: 700px) {

    .modal__content-row {
        flex-direction: column;
        gap: 1rem;
    }

    .modal__header {
        flex-direction: column;
        gap: 1rem
    }

}
