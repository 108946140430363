* {
  box-sizing: border-box; 
  --base-300: #ffffff;
  --base-400: #f5f7f9;
  --base-500: #d7d8e6;
  --base-800: #221f2b;
  --base-800: #171717;
  --text-300: #797e99;
  --text-400: #565a75;
  --text-500: #323346;
  --accent-300: #a0a2f8;
  --accent-500: #404eed;
  --shadow-500: rgba(42, 41, 124, .15);
  --shadow-800: rgba(19, 19, 26, 0.5);
}

html {
  scroll-behavior: smooth;
}

button {
  padding: 0;
  cursor: pointer;
  border: none;
  background: transparent;
}

textarea {
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  transition: color .5s;
}

a:hover {
  color: var(--accent-500);
}

b {
  color: #6b6ea7;
  font-weight: 600;
}

ol, ul {
  margin: 0;
}

p, h1, h2, h3, h4, h5 {
  margin: 0;
}

h1, h2 {
  color: var(--text-500);
  font-family: 'Montserrat';
}

h3, h4, h5 {
  color: var(--text-400);
  font-family: 'Montserrat';
}

body {
  margin: 0;
  color: var(--text-300);
  background-color: var(--base-300);
  overflow-x: hidden;
  font-family: 'Inter', -apple-system, system-ui, sans-serif;
} 

::selection {
  background-color: var(--accent-300);
  color: var(--base-300);
}

.app {
  position: relative;
  display: flex;
  flex-direction: column;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--base-500);
}

.waiting-pointer {
  margin: auto auto 0 0;
  width: .6rem;
  height: .25rem;
  opacity: 0;
  background-color: var(--base-400);
  animation-name: waiting;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.waiting-pointer--about {
  background-color: var(--text-500);
  height: .5rem;
  width: 1rem;
}

@font-face {
  font-family: 'Consola';
  src: url('./fonts/Consola.ttf');
}

@font-face {
  font-family: 'ArchitectsDaughter';
  src: url('./fonts/ArchitectsDaughter.ttf');
}

@font-face {
  font-family: 'Comfortaa';
  src: url('./fonts/Comfortaa.ttf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat.ttf');
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter.ttf');
}

@keyframes waiting{
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rise-up-from-below {
  0% {
    opacity: 0; 
    transform: translate(0, 8rem);
  }
  100% {
    opacity: 1;
  }
}

@keyframes drop-in-from-above {
  0% {
    opacity: 0;
    transform: translate(0, -8rem);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    transform: scale(.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
